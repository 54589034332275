<template>
    <div class="con-wrap">
        <CarrotTitle title="CARROT Manual"></CarrotTitle>
        <div class="board">
            <div class="mt-40">

                <div>
                    <table class="table-row table-serach">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>제목</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="bbs.title">
                                </td>
                                <th>작성자</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="bbs.writer">
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td colspan="3">
                                    <input type="text" class="w-100per" v-model="bbs.content">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-right mt-10" @click="bbs.getList()">검색</button>
                    <div class="clear"></div>
                </div>

                <div class="mt-50 mb-20">
                    <select class="w-150px float-left" v-model="bbs.ca_name" @change="bbs.getList">
                        <option value="">카테고리 전체</option>
                        <option :value="irow" v-for="(irow, i) in bbs.ca_list" :key="i">{{ irow }}</option>
                    </select>
                    <button class="btn-default float-right" @click="bbs.showAdd()">새글 작성하기</button>
                    <div class="clear"></div>
                </div>
                
                <table class="table-col">
                    <colgroup>
                        <col width="80">
                        <col width="670">
                        <col width="150">
                        <col width="150">
                        <col width="150">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>제목</th>
                            <th>작성자</th>
                            <th>작성일</th>
                            <th>조회수</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in bbs.list" :key="i">
                            <td>{{ irow.topfix=='Y'?'-':irow.num }}</td>
                            <td class="txt-left" @click="bbs.showView(irow.idx)">
                                <span class="btn-view">{{ irow.title }}</span>
                                <span class="list-num ml-5" v-if="irow.cnt_cmt>0">({{ irow.cnt_cmt }})</span>
                                <span class="ico-n ml-5" v-if="irow.is_new=='Y'">NEW</span>
                            </td>
                            <td class="txt-ellipsis">{{ irow.ename }}({{ irow.kname }})</td>
                            <td>{{ irow.wdate }}</td>
                            <td>{{ irow.hit }}</td>
                        </tr>
                        <tr v-if="bbs.total==0">
                            <td colspan="5">등록된 포스트가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'


export default {
    layout:"CARROTZone",
    components: {
    },
    setup() {
        const router = useRouter()
        const toast  = useToast()

        const bbs = reactive({
            isMounted : false,
            ca_list : [ '공통','교육','업무','규정&양식','복지' ],

            page    : 1,
            rows    : 50,
            title   : "",
            writer  : "",
            content : "",
            ca_name : "",
            list : [], total : 0,

            setTab : (id) => {
                bbs.ca_name = id;
                bbs.page = 1;
                bbs.getList();
            },

            getList: () => {
                if( bbs.isMounted == false ) return;

                let params = {
                    page    : bbs.page,
                    title   : bbs.title,
                    writer  : bbs.writer,
                    content : bbs.content,
                    ca_name : bbs.ca_name,
                }
                axios.get('/rest/carrotzone/carrotmanualList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        // console.info(res.data.err_msg);
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                router.push({
                    name   : 'CARROTZone-CarrotManualView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                router.push({ name : 'CARROTZone-CarrotManualAdd' });
            }
        });
        
        onMounted(() => {
            // Mounted
            bbs.isMounted = true;

            bbs.getList();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>